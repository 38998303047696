<template>
  <div>
    <sidebar />
    <div class="main">
      <upbar title="Projects" class="pb-5" /> <br />
      <!-- ALERTS -->
      <div>
        <b-alert
          v-if="creationState && creationMessage != ''"
          variant="success"
          disimissable
          show="5"
          >{{ creationMessage }}</b-alert
        >
        <b-alert
          v-if="!creationState && creationMessage != ''"
          variant="danger"
          disimissable
          show="5"
          >Error creating project: {{ creationMessage }}</b-alert
        >
      </div>
      <div class="plus" v-b-modal.create-project @click="emptyCreationMessage">
        <div class="plusIn">
          <img src="../assets/plus.png" class="iconPlus" />
          <span> New Project </span>
        </div>
      </div>
      <br />
      <div class="bar row">
        <div class="col-4">
          <p class="tabTitle">Project Name</p>
        </div>
        <div class="col-4">
          <p class="tabTitle">Creation Date</p>
        </div>
        <div class="col-4">
          <p class="tabTitle">Actions</p>
        </div>
      </div>

      <div v-for="project in projects" :key="project.title" class="bar1 row">
        <div class="col-4">
          <p class="tabText touch" v-on:click="openProject(project.id)">
            {{ project.name }}
          </p>
        </div>
        <div class="col-4">
          <p class="tabText touch" v-on:click="openProject(project.id)">{{ project.timestamp.split("T")[0] }}</p>
        </div>
        <div class="col-4">
          <div class="buttons">
            <b-icon
              class="icon touch" 
              variant="success"
              icon="arrow-up-right-circle-fill"
              aria-hidden="true"
              title="Open"
              @click="openProject(project.id)"
            >
            </b-icon>
            <b-icon
              class="icon touch" 
              variant="primary"
              icon="pencil-square"
              aria-hidden="true"
               title="Modify"
              @click="modalEditProject(project.id, project.name)"
            >
            </b-icon>
            <b-icon
              class="icon touch"
              variant="danger"
              icon="trash-fill"
              aria-hidden="true"
              title="Delete"
              @click="modalDeleteProject(project.id)"
            >
            </b-icon>
          </div>
        </div>
      </div>
    </div>
    <b-modal size="xl" id="create-project" hide-footer>
      <createProject @creation-state="showCreationState" />
    </b-modal>
    <b-modal size="xl" id="delete-project" hide-footer>
      <deleteProject
        @delete-project="finishProjectDeletion"
        :projectId="projectId"
      />
    </b-modal>
    <b-modal size="xl" id="edit-project" hide-footer>
      <editProject
        :projectId="projectId"
        :projectName="projectName"
        @edit-project="finishProjectUpdate"
      />
    </b-modal>
  </div>
</template>

<script>
import api from "../api/index";
import sidebar from "../components/sidebar.vue";
import upbar from "../components/upbar.vue";
import createProject from "../components/popup/createProject.vue";
import deleteProject from "../components/popup/deleteProject.vue";
import editProject from "../components/popup/editProject.vue";
import store from "@/store";

export default {
  name: "Homepage",
  components: {
    sidebar,
    upbar,
    createProject,
    deleteProject,
    editProject,
  },
  data() {
    return {
      projects: [],
      logged: false,
      creationState: null,
      creationMessage: "",
      projectId: 0,
      projectName: "",
      customer: "",
    };
  },
  mounted() {
    this.logged = this.$store.getters.isAuthenticated;
  },
  created() {
    this.getProjects();
    this.getCustomerData();
  },
  methods: {
    getCustomerData() {
      api.getCustomer().then((response) => {
        this.customer = [
          response.responseData.firstName,
          response.responseData.lastName,
        ].join(" ");
        store.commit("saveCustomerName", this.customer);
      });
    },
    emptyCreationMessage() {
      this.creationMessage = "";
    },
    finishProjectDeletion(projectId) {
      const identifiedProject = this.projects.findIndex((x) => x.id === projectId);
      this.projects.splice(identifiedProject, 1);
    },
    finishProjectUpdate(name, projectId, message) {
      if(message==='Project updated successfully.'){
        const identifiedProject = this.projects.find((x) => x.id === projectId);
        identifiedProject.name = name.name;
      }
      else{
        console.log('Unable to edit project');
      }
    },
    modalEditProject(projectId, projectName) {
      this.projectId = projectId;
      this.projectName = projectName;
      this.$bvModal.show("edit-project");
    },
    modalDeleteProject(projectId) {
      this.projectId = projectId;
      this.$bvModal.show("delete-project");
    },
    showCreationState(creationState, message) {
      this.getProjects();
      this.creationState = creationState;
      this.creationMessage = message;
    },
    getProjects() {
      api.getProjects().then((response) => {
        this.projects = response.responseData;
      });
    },
    openProject(id) {
      this.$store.commit("saveProject", id);
      this.$router.push({ path: "/connectors/" + id });
    },
  },
};
</script>

<style scoped>
.main {
  margin-top: 53px;
  margin-left: 370px;
}
.bar {
  margin-top: 53px;
  margin-right: 40px;
  height: 53px;
  border-radius: 2px;
  background: #f2f6ff;
}
.bar1 {
  margin-right: 40px;
  height: 82px;
  border-radius: 2px;
  border-bottom: 2px solid #dfe0eb;
}
.tabTitle {
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  color: #2a4192;
}
.tabText {
  margin-top: 28px;
  font-size: 20px;
  line-height: 21px;
  color: #252733;
}
.buttons {
  margin-top: 18px;
}
.botton {
  width: 155px;
  height: 44px;
  margin-right: 25px;
}
.icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.touch {
  cursor: pointer;
}
.plus {
  margin-right: 40px;
  float: right;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}
.plusIn {
  margin-top: 10px;
  margin-left: 40px;
}
.iconPlus {
  margin-right: 20px;
}
</style>
