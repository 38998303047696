<template>
  <div>
    <div class="title">Edit Project</div>
    <div class="form">
      <p class="subtitle mt-3">Project Name</p>
      <b-form-input
        v-model="form.name"
        type="text"
        placeholder="Project Name"
        class="shape col-9"
      ></b-form-input>
    </div>

    <div class="button mb-5">
      <b-button variant="primary" class="botton" @click="editProject()"
        >Apply</b-button
      >
    </div>
  </div>
</template>

<script>
import api from "@/api/index";
export default {
  name: "EditProject",
  props: {
    projectName: String,
    projectId: Number,
  },
  data() {
    return {
      form: {
        name: "",
      },
    };
  },
  methods: {
    editProject() {
      api
        .updateProject(this.form, this.projectId)
        .then(() => {
          const message = "Project updated successfully.";
          this.$emit("edit-project", this.form, this.projectId, message);
          this.$bvModal.hide("edit-project");
        })
        .catch((error) => {
          const message = error.responseData;
          this.$emit("edit-project",this.form,  this.projectId, message);
          this.$bvModal.hide("edit-project");
        });
    },
    getSettings() {
      return {
        name: this.form.name,
      };
    },
  },
  mounted() {
    this.form.name = this.projectName;
  },
};
</script>

<style scoped>
.all {
  text-align: center;
}
.form {
  margin-left: 28%;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.shape {
  width: 480px;
  height: 56px;
}
.botton {
  margin-top: 30px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.button {
  text-align: center;
}
</style>
